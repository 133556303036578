export const NEW = 'new'
export const PARTIAL_PACKED = 'partial_packed'
export const PACKED = 'packed'
export const CHECKED = 'checked'
export const DISPATCHED = 'dispatched'

export const ALL_DISPATCH_STATES = [
  NEW,
  PARTIAL_PACKED,
  PACKED,
  CHECKED,
  DISPATCHED,
]
