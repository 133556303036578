import {makeStyles} from '@mui/styles'
import {If} from 'babel-plugin-jsx-control-statements'
import {keyBy} from 'lodash'
import PropTypes from 'prop-types'
import {Fragment} from 'react'
import useControlledValue from '../../../hooks/useControlledValue'
import Chip from '../../visual/mui/Chip'
import FormControl from '../../visual/mui/FormControl'
import FormHelperText from '../../visual/mui/FormHelperText'
import InputLabel from '../../visual/mui/InputLabel'
import MenuItem from '../../visual/mui/MenuItem'
import MuiSelect from '../../visual/mui/Select'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: '256px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '225px',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-7px 2px',
  },
  formHelperText: {
    paddingLeft: '6px',
  },
}))

const Select = ({
  fullWidth,
  value,
  label,
  options,
  disabled,
  required,
  onChange,
  multiple,
  error,
  helperText,
  ...props
}) => {
  const {controlledValue, controlledOnChange} = useControlledValue(
    value,
    onChange,
  )
  const classes = useStyles()
  const optionsByValue = keyBy(options, 'value')
  const format = (value) => value || (multiple ? [] : '')

  const renderValue = (selected) => {
    if (!selected) return null
    if (!multiple) return optionsByValue[selected]?.label
    return (
      <div className={classes.chips}>
        {selected.map((item) => (
          <Fragment key={item}>
            <If condition={optionsByValue[item]}>
              <Chip label={optionsByValue[item]?.label} />
            </If>
          </Fragment>
        ))}
      </div>
    )
  }

  return (
    <FormControl
      required={required}
      error={error}
      fullWidth={fullWidth}
      className={classes.formControl}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        multiple={multiple}
        disabled={disabled}
        label={label /* Must be present in outlined */}
        value={format(controlledValue)}
        onChange={controlledOnChange}
        renderValue={renderValue}
        {...props}
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText className={classes.formHelperText}>
        {helperText}
      </FormHelperText>
    </FormControl>
  )
}

Select.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

export default Select
