import {PhoneNumberUtil} from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

export const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (_error) {
    return false
  }
}
