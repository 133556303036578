import S from 'fluent-json-schema'
import {ALL_PERMISSIONS} from '../../constants/permissions'
import * as baseSchemas from './baseSchemas'

export const login = {
  email: baseSchemas.email().required(),
  password: baseSchemas.standardString().required(),
  remember: S.boolean().default(false),
}

export const user = {
  firstName: baseSchemas.standardString().required(),
  lastName: baseSchemas.standardString().required(),
  email: baseSchemas.email().required(),
  oldPassword: baseSchemas.standardString(),
  password: baseSchemas.standardString().required(),
  apps: baseSchemas.array(baseSchemas.app()),
  roleId: baseSchemas.id().required(),
}

export const client = {
  name: baseSchemas.standardString().required(),
  address1: baseSchemas.standardString(),
  address2: baseSchemas.standardString(),
  city: baseSchemas.standardString(),
  zipCode: baseSchemas.standardString(),
  country: baseSchemas.standardString(),
  companyType: baseSchemas.standardString(),
  industrySector: baseSchemas.standardString(),
  ico: baseSchemas.standardString().raw({nullable: true}),
  dic: baseSchemas.standardString().raw({nullable: true}),
  icDph: baseSchemas.standardString().raw({nullable: true}),
  discount: S.number().maximum(100), // .multipleOf(0.01)

  statutories: baseSchemas
    .array(
      baseSchemas.object({
        firstName: baseSchemas.standardString(),
        lastName: baseSchemas.standardString().required(),
        email: baseSchemas.email(),
        phone: baseSchemas.standardString(),
        function: baseSchemas.standardString(),
      }),
    )
    .raw({nullable: true}),
}

export const updateClient = {
  ...client,

  statutories: baseSchemas
    .array(
      baseSchemas.object({
        id: baseSchemas.id(),
        firstName: baseSchemas.standardString(),
        lastName: baseSchemas.standardString().required(),
        email: S.oneOf([baseSchemas.email(), S.string().enum([''])]),
        phone: baseSchemas.standardString(),
        function: baseSchemas.standardString(),
      }),
    )
    .raw({nullable: true}),
}

export const role = {
  name: baseSchemas.standardString().required(),
  permissions: S.array().items(
    baseSchemas.standardString().enum(ALL_PERMISSIONS),
  ),
}

export const schedule = {
  begin: baseSchemas.timestamp().required(),
  end: baseSchemas.timestamp().required(),
  frequency: baseSchemas.nullableEnum(baseSchemas.scheduleFrequencies()),
  interval: S.integer().minimum(1).raw({nullable: true}),
  until: baseSchemas.timestamp().raw({nullable: true}),
  name: baseSchemas.standardString(),
  color: baseSchemas.color().raw({nullable: true}),
  apps: baseSchemas.nullable(baseSchemas.array(baseSchemas.app()).minItems(1)),
}

export const paymentTerm = {
  name: baseSchemas.standardString().required(),
}

export const shippingMethod = {
  name: baseSchemas.standardString().required(),
}
