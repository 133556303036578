import {format} from 'date-fns'

const dateCell = ({
  format: formatStr = 'dd.MM.yyyy, HH:mm',
  fallback = '–',
} = {}) => {
  const renderDateCell = ({value}) => {
    if (!value) return fallback

    return format(new Date(value), formatStr)
  }
  return renderDateCell
}

export default dateCell
