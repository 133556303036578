import ErrorIcon from '@mui/icons-material/Error'
import {navigate} from '@reach/router'
import PropTypes from 'prop-types'
import {clearAllSettings} from '../../hooks/useLocalSetting'
import {ErrorScreen} from '../visual/'

const ErrorFallback = ({resetError}) => {
  const handleNavigateHome = () => {
    navigate('/')
    resetError()
  }

  const handleReset = () => {
    navigate('/')
    clearAllSettings()
    resetError()
  }

  return (
    <ErrorScreen
      Icon={ErrorIcon}
      title="Neočakávaná chyba"
      buttonLabel="Resetovať aplikáciu"
      homeButtonLabel="Domov"
      onHomeClick={handleNavigateHome}
      onClick={handleReset}
    >
      <div>
        Správa o chybe bola odoslaná. Budeme urýchlene pracovať na jej
        odstránení.
      </div>
    </ErrorScreen>
  )
}

ErrorFallback.propTypes = {
  resetError: PropTypes.func.isRequired,
}

export default ErrorFallback
