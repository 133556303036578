import {isRequired, withForm} from 'common-fe/forms'
import * as resources from '../../../../../constants/resources'
import * as baseSchemas from '../../../../common/baseSchemas'
import * as schemas from '../../../../common/schemas'
import TextInput from '../../../components/form/TextInput/TextInput'
import {useAsyncUniqueValidate} from '../../../hooks/useAsyncUniqueValidate'

const ShippingMethodsForm = () => {
  const validateName = useAsyncUniqueValidate({
    app: null,
    resource: resources.SHIPPING_METHODS,
    column: 'name',
  })
  return (
    <TextInput
      name="name"
      label="Názov"
      required={isRequired(schemas.shippingMethod)}
      fieldProps={{validate: validateName}}
    />
  )
}

const schema = baseSchemas.object(schemas.shippingMethod)

export default withForm({schema})(ShippingMethodsForm)
