import {Router as ReachRouter} from '@reach/router'
import {If} from 'babel-plugin-jsx-control-statements'
import {lazy, Suspense} from 'react'
import {QueryErrorResetBoundary} from 'react-query'
import * as apps from '../../constants/apps'
import {hasPermissions} from '../common/auth'
import Router from './Router'
import AppShell from './components/containers/AppShell/AppShell'
import ErrorFallback from './components/screens/ErrorFallback'
import Loading from './components/screens/Loading'
import Login from './components/screens/login/Login'
import {ApiProvider} from './hooks/api'
import {AuthProvider, useSession} from './hooks/auth'
import {AlertsProvider} from './hooks/useAlert'
import useLocalSetting from './hooks/useLocalSetting'
import {WidgetControlProvider} from './hooks/widgetControl'
import Sentry from './sentry'
import ThemeProvider from './theme/ThemeProvider'

const MegaSealRouter = lazy(
  () => import(/* webpackChunkName: "megaseal" */ './apps/megaseal'),
)
const MegaShopRouter = lazy(
  () => import(/* webpackChunkName: "megashop" */ './apps/megashop'),
)
const ExpeditionRouter = lazy(() => import('./apps/expedition'))

const Screens = () => {
  const [openMenu, setOpenMenu] = useLocalSetting('openMenu', {
    isOpen: false,
  })
  const session = useSession()

  const menuClick = () => {
    setOpenMenu(({isOpen}) => ({isOpen: !isOpen}))
    setTimeout(() => window.dispatchEvent(new Event('resize')), 300)
  }

  if (!session) return <Login />

  return (
    <AppShell onMenuClick={menuClick} openMenu={openMenu.isOpen}>
      <Suspense fallback={<Loading />}>
        <ReachRouter>
          <Router openMenu={openMenu.isOpen} path="/*" />
          <If condition={hasPermissions(session.apps, [apps.MEGASEAL])}>
            <MegaSealRouter openMenu={openMenu.isOpen} path="/megaseal/*" />
          </If>
          <If condition={hasPermissions(session.apps, [apps.MEGASHOP])}>
            <MegaShopRouter openMenu={openMenu.isOpen} path="/megashop/*" />
          </If>
          <If condition={hasPermissions(session.apps, [apps.EXPEDITION])}>
            <ExpeditionRouter openMenu={openMenu.isOpen} path="/expedition/*" />
          </If>
        </ReachRouter>
      </Suspense>
    </AppShell>
  )
}

const App = () => {
  const handleResetError =
    (resetError) =>
    (...args) => {
      return resetError(...args)
    }

  return (
    <QueryErrorResetBoundary>
      {({reset}) => (
        <Sentry.ErrorBoundary
          onReset={reset}
          fallback={({resetError}) => (
            <ErrorFallback resetError={handleResetError(resetError)} />
          )}
          showDialog
          dialogOptions={{lang: 'sk'}}
        >
          <ThemeProvider>
            <AlertsProvider>
              <AuthProvider>
                <ApiProvider>
                  <WidgetControlProvider>
                    <ReachRouter>
                      <Screens default />
                    </ReachRouter>
                  </WidgetControlProvider>
                </ApiProvider>
              </AuthProvider>
            </AlertsProvider>
          </ThemeProvider>
        </Sentry.ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  )
}

export default App
