export const MEGASHOP_HOMEPAGE = 'megashop_homepage'
export const MEGASHOP_FOOTER = 'megashop_footer'
export const MEGASHOP_REGISTRATION_EMAIL = 'megashop_registration_email'
export const MEGASHOP_REGISTRATION_NOTICE = 'megashop_registration_notice'
export const MEGASHOP_PASSWORD_RESET_EMAIL = 'megashop_password_reset_email'
export const MEGASHOP_EMAIL_VALIDATION_EMAIL = 'megashop_email_validation_email'
export const MEGASHOP_PASSWORD_REVERT_EMAIL = 'megashop_password_revert_email'
export const MEGASHOP_ORDER_SUBMIT_NOTICE = 'megashop_order_submit_notice'
export const MEGASHOP_CONTENT_AFTER_PRODUCTS = 'megashop_content_after_products'
export const MEGASHOP_PASSWORD_SET_EMAIL = 'megashop_password_set_email'
export const MEGASHOP_USER_VERIFIED_EMAIL = 'megashop_user_verified_email'

export const ALL_MEGASHOP_SYSTEM_SETTINGS = [
  MEGASHOP_HOMEPAGE,
  MEGASHOP_FOOTER,
  MEGASHOP_REGISTRATION_EMAIL,
  MEGASHOP_REGISTRATION_NOTICE,
  MEGASHOP_PASSWORD_RESET_EMAIL,
  MEGASHOP_EMAIL_VALIDATION_EMAIL,
  MEGASHOP_PASSWORD_REVERT_EMAIL,
  MEGASHOP_ORDER_SUBMIT_NOTICE,
  MEGASHOP_CONTENT_AFTER_PRODUCTS,
  MEGASHOP_PASSWORD_SET_EMAIL,
  MEGASHOP_USER_VERIFIED_EMAIL,
]
