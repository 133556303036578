import * as EXPEDITION from './expedition/expeditionResources'
import * as MEGASEAL from './megaseal/megasealResources'
import * as MEGASHOP from './megashop/megashopResources'

export const CLIENTS = 'clients'
export const LOCKS = 'locks'
export const ROLES = 'roles'
export const SESSIONS = 'sessions'
export const SYSTEM_SETTINGS = 'systemSettings'
export const USERS = 'users'
export const SCHEDULES = 'schedules'
export const IMAGES = 'images'
export const DOWNLOADS = 'downloads'
export const PAYMENT_TERMS = 'paymentTerms'
export const SHIPPING_METHODS = 'shippingMethods'

export {MEGASEAL, MEGASHOP, EXPEDITION}
