import {castArray} from 'lodash'
import allEnums from '../../../../constants/enums'
import {Chip} from '../../../visual'

const enumCell = (enumName, fallback = '–') => {
  const renderEnumCell = ({value}) => {
    if (!value) return <Chip label={fallback} />
    return castArray(value).map((item, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <Chip key={idx} label={allEnums[enumName][item]} />
    ))
  }
  return renderEnumCell
}

export default enumCell
