import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ImageIcon from '@mui/icons-material/Image'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import PaymentIcon from '@mui/icons-material/Payment'
import PersonIcon from '@mui/icons-material/Person'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import {Link} from '@reach/router'
import {If} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
import {lazy} from 'react'
import * as permissions from '../../constants/permissions'
import {hasPermissions} from '../common/auth'
import Screen from './components/containers/Screen/Screen'
import NotFound from './components/screens/NotFound'
import ClientsCreate from './components/screens/clients/ClientsCreate'
import ClientsEdit from './components/screens/clients/ClientsEdit'
import ClientsList from './components/screens/clients/ClientsList'
import Dashboard from './components/screens/dashboard/Dashboard'
import MediaLibraryList from './components/screens/mediaLibrary/MediaLibraryList'
import PaymentTermsList from './components/screens/paymentTerms/PaymentTermsList'
import ProfileEdit from './components/screens/profile/ProfileEdit'
import RolesList from './components/screens/roles/RolesList'
import ShippingMethodsList from './components/screens/shippingMethods/ShippingMethodsList'
import UsersCreate from './components/screens/users/UsersCreate'
import UsersEdit from './components/screens/users/UsersEdit'
import UsersList from './components/screens/users/UsersList'
import {ContentContainer} from './components/visual'
import Navigation from './components/visual/Navigation/Navigation'
import NavigationItem from './components/visual/Navigation/NavigationItem'
import NavigationList from './components/visual/Navigation/NavigationList'
import {useAuth, useSession} from './hooks/auth'
import ThemeProvider from './theme/ThemeProvider'

const SystemSettingsList = lazy(
  () =>
    import(
      /* webpackChunkName: "megaseal" */ './components/screens/systemSettings/SystemSettingsList'
    ),
)
const SystemSettingsEdit = lazy(
  () =>
    import(
      /* webpackChunkName: "megaseal" */ './components/screens/systemSettings/SystemSettingsEdit'
    ),
)

const Router = ({openMenu}) => {
  const {logout} = useAuth()
  const session = useSession()

  return (
    <ThemeProvider>
      <ContentContainer>
        <Navigation open={openMenu}>
          <NavigationList open={openMenu}>
            <NavigationItem component={Link} to="/" icon={<DashboardIcon />}>
              Prehľad
            </NavigationItem>
            <If
              condition={hasPermissions(session.permissions, [
                permissions.MANAGE_CLIENTS,
              ])}
            >
              <NavigationItem
                component={Link}
                to="/clients"
                icon={<AssignmentIndIcon />}
              >
                Odoberatelia
              </NavigationItem>
            </If>
            <If
              condition={hasPermissions(session.permissions, [
                permissions.MANAGE_USERS,
              ])}
            >
              <NavigationItem
                component={Link}
                to="/users"
                icon={<PersonIcon />}
              >
                Pracovníci
              </NavigationItem>
            </If>
            <If
              condition={hasPermissions(session.permissions, [
                permissions.MANAGE_SYSTEM_SETTINGS,
              ])}
            >
              <NavigationItem
                component={Link}
                to="/systemSettings"
                icon={<SettingsApplicationsIcon />}
              >
                Systémové nastavenia
              </NavigationItem>
              <NavigationItem
                component={Link}
                to="/shippingMethods"
                icon={<LocalShippingIcon />}
              >
                Spôsob odberu
              </NavigationItem>
              <NavigationItem
                component={Link}
                to="/paymentTerms"
                icon={<PaymentIcon />}
              >
                Formy úhrady
              </NavigationItem>
            </If>
            <NavigationItem
              component={Link}
              to="/mediaLibrary"
              icon={<ImageIcon />}
            >
              Obrázky
            </NavigationItem>
            <NavigationItem icon={<PowerSettingsNewIcon />} onClick={logout}>
              Odhlásiť sa
            </NavigationItem>
          </NavigationList>
        </Navigation>
        <Screen>
          <Dashboard path="/" />
          <If
            condition={hasPermissions(session.permissions, [
              permissions.MANAGE_USERS,
            ])}
          >
            <UsersList path="/users" />
            <UsersCreate path="/users/create" />
            <UsersEdit path="/users/:userId" />
            <RolesList path="/roles" />
          </If>
          <If
            condition={hasPermissions(session.permissions, [
              permissions.MANAGE_CLIENTS,
            ])}
          >
            <ClientsList path="/clients" />
            <ClientsCreate path="/clients/create" />
            <ClientsEdit path="/clients/:clientId" />
          </If>
          <If
            condition={hasPermissions(session.permissions, [
              permissions.MANAGE_SYSTEM_SETTINGS,
            ])}
          >
            <SystemSettingsList path="/systemSettings" />
            <SystemSettingsEdit path="/systemSettings/:systemSettingId" />
            <ShippingMethodsList path="/shippingMethods" />
            <PaymentTermsList path="/paymentTerms" />
          </If>
          <MediaLibraryList path="/mediaLibrary" />
          <ProfileEdit path="/profile" />
          <NotFound default />
        </Screen>
      </ContentContainer>
    </ThemeProvider>
  )
}

Router.propTypes = {
  openMenu: PropTypes.bool,
}

export default Router
