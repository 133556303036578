import {If} from 'babel-plugin-jsx-control-statements'
import {useState} from 'react'
import * as resources from '../../../../../constants/resources'
import DeleteButton from '../../../components/containers/DeleteButton/DeleteButton'
import Filters from '../../../components/containers/Filters/Filters'
import SearchBoxInput from '../../../components/form/SearchBoxInput/SearchBoxInput'
import {ScreenHeader} from '../../../components/visual'
import {useDeleteManyResources, useListResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import useLocalSetting from '../../../hooks/useLocalSetting'
import PaymentTermsCreate from './PaymentTermsCreate'
import PaymentTermsEdit from './PaymentTermsEdit'
import PaymentTermsTable from './PaymentTermsTable'

export const queryDef = ({query} = {}) => ({
  app: '',
  resource: resources.PAYMENT_TERMS,
  query,
})

const initialQuery = {
  page: 0,
  pageSize: 10,
  filter: {deleted: false},
}

const queryName = 'paymentTermsQuery'

const PaymentTermsList = () => {
  const [selectedIds, setSelectedIds] = useState([])
  const [query, setQuery] = useLocalSetting(queryName, initialQuery)
  const {
    data: paymentTerms,
    total,
    refetch,
    isFetching,
  } = useListResource(queryDef({query}))
  const deletePaymentTerms = useDeleteManyResources(
    null,
    resources.PAYMENT_TERMS,
  )
  const [paymentTermId, setPaymentTermId] = useState(null)
  const showAlert = useAlert()

  const handleDelete = async () => {
    await deletePaymentTerms.mutateAsync(selectedIds)

    setSelectedIds([])
    await refetch()
    showAlert('Vymazané', 'success')
  }

  return (
    <>
      <ScreenHeader title="Formy úhrady">
        <DeleteButton
          onDelete={handleDelete}
          selectedIds={selectedIds}
          disabled={isFetching}
          data-test-id="deleteButton"
        />
        <PaymentTermsCreate refetch={refetch} />
      </ScreenHeader>
      <Filters query={query} setQuery={setQuery}>
        <SearchBoxInput
          fullWidth={false}
          name="q"
          label="Vyhľadať formu úhrady"
        />
      </Filters>
      <PaymentTermsTable
        paymentTerms={paymentTerms}
        total={total}
        query={query}
        setQuery={setQuery}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        setPaymentTermId={setPaymentTermId}
      />
      <If condition={paymentTermId}>
        <PaymentTermsEdit
          setPaymentTermId={setPaymentTermId}
          paymentTermId={paymentTermId}
          refetch={refetch}
        />
      </If>
    </>
  )
}

export default PaymentTermsList
