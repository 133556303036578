import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import PropTypes from 'prop-types'
import * as resources from '../../../../../constants/resources'
import Button from '../../../components/containers/Button/Button'
import {Modal} from '../../../components/visual'
import {useOneResource, useUpdateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import PaymentTermsForm from './PaymentTermsForm'

export const queryDef = ({paymentTermId}) => ({
  app: '',
  resource: resources.PAYMENT_TERMS,
  id: paymentTermId,
})

const PaymentTermsEdit = ({refetch, setPaymentTermId, paymentTermId}) => {
  const [paymentTerm] = useOneResource(queryDef({paymentTermId}))
  const updatePaymentTerm = useUpdateResource(
    null,
    resources.PAYMENT_TERMS,
    paymentTermId,
  )
  const showAlert = useAlert()

  const handleClose = () => {
    setPaymentTermId(null)
  }

  const handleSubmit = async (values) => {
    await updatePaymentTerm.mutateAsync(values)
    showAlert(`Forma úhrady ${paymentTermId} uložená`, 'success')
    if (refetch) refetch()
    handleClose()
    // Still need to handle errors somehow
  }

  if (!paymentTerm) return null

  return (
    <PaymentTermsForm onSubmit={handleSubmit} initialValues={paymentTerm}>
      {({submitting, form, handleSubmit}) => (
        <Modal open onClose={handleClose}>
          <Modal.Title>Forma úhrady {paymentTermId}</Modal.Title>
          <Modal.Content>{form}</Modal.Content>
          <Modal.Actions>
            <Button icon={<CloseIcon />} onClick={handleClose}>
              Zavrieť
            </Button>
            <Button
              icon={<SaveIcon />}
              variant="contained"
              disabled={submitting}
              onClick={handleSubmit}
            >
              Uložiť
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </PaymentTermsForm>
  )
}

PaymentTermsEdit.propTypes = {
  refetch: PropTypes.func,
  setPaymentTermId: PropTypes.func.isRequired,
  paymentTermId: PropTypes.number.isRequired,
}

export default PaymentTermsEdit
