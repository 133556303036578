import {isFunction} from 'lodash'
import {useState} from 'react'

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (_error) {
      return initialValue
    }
  })

  const setValue = (value) => {
    try {
      if (isFunction(value)) {
        setStoredValue(value(storedValue))
        return window.localStorage.setItem(
          key,
          JSON.stringify(value(storedValue)),
        )
      }
      setStoredValue(value)
      return window.localStorage.setItem(key, JSON.stringify(storedValue))
    } catch (error) {
      window.localStorage.removeItem(key)
      throw error
    }
  }

  return [storedValue, setValue]
}

export default useLocalStorage
