import {Router as ReachRouter} from '@reach/router'
import PropTypes from 'prop-types'
import {Suspense} from 'react'
import {AppContainer, Footer} from '../../visual'
import Loading from '../../visual/Loading/Loading'

const Screen = ({children, ...props}) => (
  <Suspense fallback={<Loading />}>
    <AppContainer {...props}>
      <ReachRouter>{children}</ReachRouter>
      <Footer
        // Provided by webpack define plugin
        // eslint-disable-next-line no-undef
        version={BUILD_ID}
        // eslint-disable-next-line no-undef
        date={BUILD_DATE}
        changelog={config.changelog}
      />
    </AppContainer>
  </Suspense>
)

Screen.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Screen
