import {find, get} from 'lodash'
import {ALL_EXPEDITION_SYSTEM_SETTINGS} from './apps/expedition/systemSettings'
import {ALL_MEGASEAL_SYSTEM_SETTINGS} from './apps/megaseal/systemSettings'
import {ALL_MEGASHOP_SYSTEM_SETTINGS} from './apps/megashop/systemSettings'

export const ALL_SYSTEM_SETTINGS = [
  ...ALL_MEGASEAL_SYSTEM_SETTINGS,
  ...ALL_MEGASHOP_SYSTEM_SETTINGS,
  ...ALL_EXPEDITION_SYSTEM_SETTINGS,
]

export const getSystemSetting = (systemSettings, systemSettingDef) =>
  get(find(systemSettings, {name: systemSettingDef.name}), 'value')
