import SaveIcon from '@mui/icons-material/Save'
import {useNavigate} from '@reach/router'
import parseSlovakNumber from 'common/utils/parseSlovakNumber'
import {filter} from 'lodash'
import PropTypes from 'prop-types'
import {useMemo} from 'react'
import * as relations from '../../../../../constants/relations'
import * as resources from '../../../../../constants/resources'
import {generateResourcePath} from '../../../../../constants/routes'
import relation from '../../../../lib/relation'
import {useOneResource, useUpdateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import Button from '../../containers/Button/Button'
import {ScreenHeader} from '../../visual'
import NotFound from '../NotFound'
import useClientsForm from './ClientsForm'

export const queryDef = ({clientId}) => ({
  app: '',
  resource: resources.CLIENTS,
  expand: relation(relations.STATUTORIES),
  id: clientId,
})

const ClientsEdit = ({clientId}) => {
  const navigate = useNavigate()
  const [client] = useOneResource(queryDef({clientId}))
  const filteredClient = useMemo(
    () => ({
      ...client,
      statutories: filter(
        client.statutories,
        (statutory) => !statutory?.deletedAt,
      ),
    }),
    [client],
  )
  const updateClient = useUpdateResource(null, resources.CLIENTS, clientId)
  const showAlert = useAlert()
  const ClientsForm = useClientsForm({isNewRecord: false})

  if (!filteredClient || filteredClient.deletedAt) return <NotFound />

  const handleSubmit = async (values) => {
    await updateClient.mutateAsync({
      ...values,
      statutories: values.statutories || [],
    })
    navigate(generateResourcePath(resources.CLIENTS))
    showAlert(`Odoberateľ ${clientId} uložený`, 'success')
    // Still need to handle errors somehow
  }

  return (
    <>
      <ClientsForm
        onSubmit={handleSubmit}
        initialValues={{
          ...filteredClient,
          statutories: filteredClient.statutories.map((statutory) => ({
            ...statutory,
            phone: parseSlovakNumber(statutory.phone),
          })),
        }}
      >
        {({submitting, form, handleSubmit}) => (
          <>
            <ScreenHeader title={`Odoberateľ ${clientId}`}>
              <Button
                icon={<SaveIcon />}
                variant="contained"
                disabled={submitting}
                onClick={handleSubmit}
              >
                Uložiť
              </Button>
            </ScreenHeader>
            {form}
          </>
        )}
      </ClientsForm>
    </>
  )
}

ClientsEdit.propTypes = {
  clientId: PropTypes.string,
}

export default ClientsEdit
