import {Choose, Otherwise, When} from 'babel-plugin-jsx-control-statements'
import {showErrorOnChange} from 'common-fe/forms'
import {isPhoneValid} from 'common-fe/utils/isPhoneValid'
import PropTypes from 'prop-types'
import {useField} from 'react-final-form'
import {StaticField, PhoneInput as VisualPhoneInput} from '../../visual'

const PhoneInput = ({
  disableSpacing,
  readOnly,
  name,
  label,
  required,
  helperText,
  fullWidth,
  showError = showErrorOnChange,
  fieldProps,
  ...props
}) => {
  const {
    input: {value, onChange, ...restInput},
    meta,
  } = useField(name, {
    ...fieldProps,
    validate: (value) => {
      if (!value) return undefined
      return isPhoneValid(value) ? undefined : 'Číslo je neplatné'
    },
  })
  const {helperTextOrError, isError} = showError({meta, helperText})
  return (
    <Choose>
      <When condition={!readOnly}>
        <VisualPhoneInput
          fullWidth={fullWidth}
          label={label}
          helperText={helperTextOrError}
          onChange={onChange}
          name={name}
          value={value}
          required={required}
          inputProps={{required, ...restInput}}
          error={isError}
          {...props}
        />
      </When>
      <Otherwise>
        <StaticField label={label} disableSpacing={disableSpacing}>
          {value || '–'}
        </StaticField>
      </Otherwise>
    </Choose>
  )
}
PhoneInput.defaultProps = {
  fullWidth: true,
}

PhoneInput.propTypes = {
  disableSpacing: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  showError: PropTypes.func,
  fieldProps: PropTypes.object,
}

export default PhoneInput
