import * as Sentry from '@sentry/react'

if (config.sentry.enabled) {
  Sentry.init({
    dsn: config.sentry.dsn,
    // Provided by webpack define plugin
    // eslint-disable-next-line no-undef
    release: `megabelt@${BUILD_ID}`,
    environment: 'production',
    integrations: [
      // eslint-disable-next-line import/namespace
      Sentry.browserTracingIntegration(),
      // eslint-disable-next-line import/namespace
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
    tracesSampleRate: config.sentry.tracesSampleRateFE,
    replaysSessionSampleRate: config.sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: config.sentry.replaysOnErrorSampleRate,
    tracingOrigins: ['localhost', 'srv.megabelt.sk', 'dev.srv.megabelt.sk'],
  })
}

export default Sentry
