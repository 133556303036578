import * as EXPEDITION from './expedition/expeditionRelations'
import * as MEGASEAL from './megaseal/megasealRelations'
import * as MEGASHOP from './megashop/megashopRelations'

export const CLIENT = 'client'
export const CLIENTS = 'clients'
export const USER = 'user'
export const USERS = 'users'
export const LOCK = 'lock'
export const LOCKS = 'locks'
export const SESSION = 'session'
export const SESSIONS = 'sessions'
export const ROLE = 'role'
export const ROLES = 'roles'
export const MEGASEAL_MANUFACTURE = 'megasealManufacture'
export const MEGASEAL_CALCULATIONS = 'megasealCalculations'
export const MEGASHOP_USER = 'megashopUser'
export const MEGASHOP_USERS = 'megashopUsers'
export const RESERVATION = 'reservation'
export const RESERVATIONS = 'reservations'
export const FILE = 'file'
export const FILES = 'files'
export const IMAGE = 'image'
export const IMAGES = 'images'
export const DOWNLOAD = 'download'
export const DOWNLOADS = 'downloads'
export const STATUTORY = 'statutory'
export const STATUTORIES = 'statutories'
export const PAYMENT_TERM = 'paymentTerm'
export const PAYMENT_TERMS = 'paymentTerms'
export const SHIPPING_METHOD = 'shippingMethod'
export const SHIPPING_METHODS = 'shippingMethods'
export const EXPEDITION_DISPATCH = 'expeditionDispatch'

export {MEGASEAL, MEGASHOP, EXPEDITION}
