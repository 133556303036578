import AddIcon from '@mui/icons-material/Add'
import CreateIcon from '@mui/icons-material/Create'
import RemoveIcon from '@mui/icons-material/Remove'
import Grid from '@mui/material/Grid2'
import {useNavigate} from '@reach/router'
import {Choose, When, If} from 'babel-plugin-jsx-control-statements'
import {isRequired, withForm} from 'common-fe/forms'
import {omit} from 'lodash'
import PropTypes from 'prop-types'
import {useMemo} from 'react'
import {useFormState} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import * as apps from '../../../../../constants/apps'
import * as resources from '../../../../../constants/resources'
import {generateResourcePath} from '../../../../../constants/routes'
import * as baseSchemas from '../../../../common/baseSchemas'
import * as schemas from '../../../../common/schemas'
import Button from '../../../components/containers/Button/Button'
import Widget from '../../../components/containers/Widget/Widget'
import WidgetContainer from '../../../components/containers/Widget/WidgetContainer'
import {
  FormSection,
  IconButton,
  Columns,
  Typography,
} from '../../../components/visual'
import {useAsyncUniqueValidate} from '../../../hooks/useAsyncUniqueValidate'
import PhoneInput from '../../form/PhoneInput/PhoneInput'
import TextInput from '../../form/TextInput/TextInput'

const ClientFields = () => {
  const validateName = useAsyncUniqueValidate({
    app: null,
    resource: resources.CLIENTS,
    column: 'name',
  })
  return (
    <Columns>
      <div>
        <TextInput
          name="name"
          label="Meno"
          required={isRequired(schemas.client.name)}
          fieldProps={{validate: validateName}}
        />
        <TextInput
          name="address1"
          label="Adresa 1"
          required={isRequired(schemas.client.address1)}
        />
        <TextInput
          name="address2"
          label="Adresa 2"
          required={isRequired(schemas.client.address2)}
        />
        <TextInput
          name="city"
          label="Mesto"
          required={isRequired(schemas.client.city)}
        />
        <TextInput
          name="zipCode"
          label="PSČ"
          required={isRequired(schemas.client.zipCode)}
        />
      </div>
      <div>
        <TextInput
          name="country"
          label="Krajina"
          required={isRequired(schemas.client.country)}
        />
        <TextInput
          name="ico"
          label="IČO"
          required={isRequired(schemas.client.ico)}
        />
        <TextInput
          name="dic"
          label="DIČ"
          required={isRequired(schemas.client.dic)}
        />
        <TextInput
          name="icDph"
          label="DIČ DPH"
          required={isRequired(schemas.client.icDph)}
        />
        <TextInput
          name="discount"
          label="Zľava"
          required={isRequired(schemas.client.discount)}
        />
      </div>
    </Columns>
  )
}

const StatutoriesFields = ({
  hasClientInputButton,
  statutoryUsers,
  defaultStatutory,
}) => {
  const navigate = useNavigate()
  const {values: client, dirty} = useFormState({
    subscription: {values: true, dirty: true},
  })

  const handleCopy = (statutory) => {
    const newUser = {
      statutory,
      client: omit(client, 'statutories'),
      firstName: statutory.firstName,
      lastName: statutory.lastName,
      email: statutory.email,
      phone: statutory.phone,
      companyRole: statutory.function,
      clientId: statutory.clientId,
      statutoryId: statutory.id,
    }
    navigate(
      generateResourcePath(apps.MEGASHOP, resources.MEGASHOP.USERS, 'create'),
      {state: {user: newUser}},
    )
  }

  const handleNavigate = (userId) => {
    navigate(
      generateResourcePath(apps.MEGASHOP, resources.MEGASHOP.USERS, userId),
    )
  }

  return (
    <FieldArray name="statutories">
      {({fields}) => (
        <>
          {fields.map((name, index) => {
            return (
              <div key={name}>
                <Grid
                  padding={1.5}
                  alignItems="center"
                  container
                  spacing={2}
                  columns={
                    hasClientInputButton ? {xs: 4} : {xs: 4, sm: 8, lg: 17}
                  }
                >
                  <Grid size={4}>
                    <TextInput
                      name={`${name}.firstName`}
                      label="Krstné meno"
                      required
                    />
                  </Grid>
                  <Grid size={4}>
                    <TextInput
                      name={`${name}.lastName`}
                      label="Priezvisko"
                      required
                    />
                  </Grid>
                  <Grid size={4}>
                    <TextInput name={`${name}.email`} label="E-mail" />
                  </Grid>
                  <Grid size={4}>
                    <PhoneInput
                      name={`${name}.phone`}
                      label="Telefónne číslo"
                    />
                  </Grid>
                  <Grid size={4}>
                    <TextInput name={`${name}.function`} label="Funkcia" />
                  </Grid>
                  <If condition={statutoryUsers}>
                    <Grid size={4}>
                      <Choose>
                        <When
                          condition={
                            client.statutories[index].id &&
                            statutoryUsers[client.statutories[index].id]
                          }
                        >
                          <Button
                            onClick={() =>
                              handleNavigate(
                                statutoryUsers[client.statutories[index].id].id,
                              )
                            }
                          >
                            Používateľ
                          </Button>
                        </When>
                        <When
                          condition={
                            client.statutories[index].email &&
                            !statutoryUsers[client.statutories[index].id] &&
                            !dirty
                          }
                        >
                          <Button
                            icon={<CreateIcon />}
                            onClick={() =>
                              handleCopy(client.statutories[index])
                            }
                          >
                            Vytvoriť používateľa
                          </Button>
                        </When>
                      </Choose>
                    </Grid>
                  </If>
                  <Grid
                    size={1}
                    offset={
                      hasClientInputButton
                        ? {xs: 3.5}
                        : {xs: 3.5, sm: 7.3, md: 7.5, lg: 'auto'}
                    }
                  >
                    <IconButton
                      color="secondary"
                      onClick={() => fields.remove(index)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            )
          })}
          <IconButton onClick={() => fields.push(defaultStatutory ?? {})}>
            <AddIcon />
          </IconButton>
        </>
      )}
    </FieldArray>
  )
}

StatutoriesFields.propTypes = {
  hasClientInputButton: PropTypes.bool,
  statutoryUsers: PropTypes.object,
  defaultStatutory: PropTypes.object,
}

const layoutName = 'clientsLayout'

const ClientsForm = ({
  clientInputButton,
  widget,
  statutoryUsers,
  defaultStatutory,
}) => {
  return clientInputButton ? (
    <>
      <ClientFields />
      <Typography variant="h6">Kontaktné osoby</Typography>
      <StatutoriesFields
        hasClientInputButton={clientInputButton}
        statutoryUsers={statutoryUsers}
        defaultStatutory={defaultStatutory}
      />
    </>
  ) : (
    <>
      <WidgetContainer name={layoutName} disableDrag disableResize>
        <Widget key="manufacture" data-grid={{w: 2, h: 8, x: 0, y: 0}}>
          <FormSection title="Odoberateľ">
            <ClientFields />
          </FormSection>
        </Widget>
      </WidgetContainer>
      <WidgetContainer name={layoutName} disableDrag disableResize>
        <Widget key="statutories" data-grid={{w: 4, h: 8, x: 0, y: 0}}>
          <FormSection title="Kontaktné osoby">
            <StatutoriesFields
              hasClientInputButton={clientInputButton}
              statutoryUsers={statutoryUsers}
              defaultStatutory={defaultStatutory}
            />
          </FormSection>
        </Widget>
      </WidgetContainer>
      {widget ? widget : null}
    </>
  )
}

ClientsForm.propTypes = {
  clientInputButton: PropTypes.bool,
  widget: PropTypes.node,
  statutoryUsers: PropTypes.object,
  defaultStatutory: PropTypes.object,
}

export const useForm = ({isNewRecord}) => {
  const schema = useMemo(
    () =>
      isNewRecord
        ? baseSchemas.object(schemas.client)
        : baseSchemas.object(schemas.updateClient),
    [isNewRecord],
  )

  return withForm({schema})(({...props}) => (
    <ClientsForm isNewRecord={isNewRecord} {...props} />
  ))
}

export default useForm
