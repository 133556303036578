import SaveIcon from '@mui/icons-material/Save'
import {useNavigate} from '@reach/router'
import PropTypes from 'prop-types'
import * as relations from '../../../../../constants/relations'
import * as resources from '../../../../../constants/resources'
import {generateResourcePath} from '../../../../../constants/routes'
import relation from '../../../../lib/relation'
import {useOneResource, useUpdateResource} from '../../../hooks/api'
import {useSession} from '../../../hooks/auth'
import {useAlert} from '../../../hooks/useAlert'
import Button from '../../containers/Button/Button'
import {ScreenHeader} from '../../visual'
import NotFound from '../NotFound'
import UsersForm from './UsersForm'

export const queryDef = ({userId}) => ({
  app: '',
  resource: resources.USERS,
  id: userId,
  expand: relation(relations.ROLE),
})

const UsersEdit = ({userId}) => {
  const navigate = useNavigate()
  const [user] = useOneResource(queryDef({userId}))
  const updateUser = useUpdateResource(null, resources.USERS, userId)
  const session = useSession()
  const showAlert = useAlert()

  if (!user || user.deletedAt) return <NotFound />
  if (user.id === session.userId)
    navigate(generateResourcePath('profile'), {replace: true})

  const handleSubmit = async (values) => {
    await updateUser.mutateAsync(values)
    navigate(generateResourcePath(resources.USERS))
    showAlert(`Pracovník ${userId} uložený`, 'success')
    // Still need to handle errors somehow
  }

  return (
    <UsersForm onSubmit={handleSubmit} initialValues={user}>
      {({submitting, form, handleSubmit}) => (
        <>
          <ScreenHeader title={`Pracovník ${userId}`}>
            <Button
              icon={<SaveIcon />}
              variant="contained"
              disabled={submitting}
              onClick={handleSubmit}
            >
              Uložiť
            </Button>
          </ScreenHeader>
          {form}
        </>
      )}
    </UsersForm>
  )
}

UsersEdit.propTypes = {
  userId: PropTypes.string,
}

export default UsersEdit
