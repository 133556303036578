import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined'
import LockIcon from '@mui/icons-material/LockOutlined'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import RefreshIcon from '@mui/icons-material/Refresh'
import SettingsIcon from '@mui/icons-material/Settings'
import {Link, useMatch} from '@reach/router'
import {If} from 'babel-plugin-jsx-control-statements'
import {without} from 'lodash'
import PropTypes from 'prop-types'
import {Fragment, useMemo} from 'react'
import {useIsFetching, useQueryClient} from 'react-query'
import * as apps from '../../../../../constants/apps'
import {generateResourcePath} from '../../../../../constants/routes'
import {hasPermissions} from '../../../../common/auth'
import TextExpedition from '../../../apps/expedition/assets/megabelt-white_expedition-text.svg'
import IconExpedition from '../../../apps/expedition/assets/megabelt_expedition-circle.svg'
import TextMegabelt from '../../../apps/megabelt/assets/megabelt-white_belt-text.svg'
import IconMegabelt from '../../../apps/megabelt/assets/megabelt_belt-circle.svg'
import TextMegacon from '../../../apps/megacon/assets/megabelt-white_con-text.svg'
import IconMegacon from '../../../apps/megacon/assets/megabelt_con-circle.svg'
import TextMegagasket from '../../../apps/megagasket/assets/megabelt-white_gasket-text.svg'
import IconMegagasket from '../../../apps/megagasket/assets/megabelt_gasket-circle.svg'
import TextMegapress from '../../../apps/megapress/assets/megabelt-white_press-text.svg'
import IconMegapress from '../../../apps/megapress/assets/megabelt_press-circle.svg'
import TextMegaseal from '../../../apps/megaseal/assets/megabelt-white_seal-text.svg'
import IconMegaseal from '../../../apps/megaseal/assets/megabelt_seal-circle.svg'
import IconMegashop from '../../../apps/megashop//assets/megabelt_shop-circle.svg'
import TextMegashop from '../../../apps/megashop/assets/megabelt_shop-text.svg'
import LogoMegabelt from '../../../assets/logo_megabelt.svg'
import {useAuth, useSession} from '../../../hooks/auth'
import {useWidgetControl} from '../../../hooks/widgetControl'
import {AppBar, Brand, ButtonMenu, IconButton, MenuItem} from '../../visual'
import AppsSwitcherIcon from '../../visual/AppsSwitcherIcon/AppsSwitcherIcon'

const appsMap = {
  [apps.MEGASEAL]: {
    icon: IconMegaseal,
    text: TextMegaseal,
  },
  [apps.MEGAGASKET]: {
    icon: IconMegagasket,
    text: TextMegagasket,
  },
  [apps.MEGABELT]: {
    icon: IconMegabelt,
    text: TextMegabelt,
  },
  [apps.MEGASHOP]: {
    icon: IconMegashop,
    text: TextMegashop,
  },
  [apps.MEGACON]: {
    icon: IconMegacon,
    text: TextMegacon,
  },
  [apps.MEGAPRESS]: {
    icon: IconMegapress,
    text: TextMegapress,
  },
  [apps.EXPEDITION]: {
    icon: IconExpedition,
    text: TextExpedition,
  },
}

const AppShell = ({onMenuClick, openMenu, children}) => {
  const {widgetControl, setWidgetControl} = useWidgetControl()
  const isFetching = useIsFetching()
  const {logout, refreshAuth} = useAuth()
  const queryClient = useQueryClient()
  const session = useSession()

  const memoizedChildren = useMemo(() => children, [openMenu]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRefresh = async () => {
    await refreshAuth()
    await queryClient.refetchQueries()
  }

  const match = useMatch('/:app/*')
  const currentApp = hasPermissions(session.apps, match?.app)
    ? match?.app
    : undefined

  const brand = (
    <Link to={currentApp ? generateResourcePath(currentApp) : '/'}>
      <Brand
        src={currentApp ? appsMap[currentApp]?.text : LogoMegabelt}
        alt={`Logo ${currentApp || 'Megabelt'}`}
      />
    </Link>
  )

  const widgetControlText = widgetControl
    ? 'Zablolokovať rozloženie'
    : 'Odblokovať rozloženie'
  const WidgetControlIcon = () => {
    if (widgetControl) return <LockIcon />
    return <LockOpenIcon />
  }

  const allSwitcherIcons = without(session.apps, currentApp)

  const handleWidgetControl = () => setWidgetControl((prevState) => !prevState)

  return (
    <>
      <AppBar
        isFetching={Boolean(isFetching)}
        onMenuClick={onMenuClick}
        brand={brand}
      >
        {allSwitcherIcons.map((app) => (
          <Fragment key={app}>
            <If condition={appsMap[app]?.icon}>
              <AppsSwitcherIcon key={app} to={generateResourcePath(app)}>
                <img src={appsMap[app].icon} alt={`Ikona ${app}`} />
              </AppsSwitcherIcon>
            </If>
          </Fragment>
        ))}
        <AppBar.Divider />
        <IconButton onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
        <ButtonMenu icon={<AccountCircleIcon />}>
          <MenuItem onClick={handleWidgetControl} icon={<WidgetControlIcon />}>
            {widgetControlText}
          </MenuItem>
          <MenuItem component={Link} to="/profile" icon={<SettingsIcon />}>
            Nastavenia
          </MenuItem>
          <MenuItem onClick={logout} icon={<PowerSettingsNewIcon />}>
            Odhlásiť
          </MenuItem>
        </ButtonMenu>
      </AppBar>
      {memoizedChildren}
    </>
  )
}

AppShell.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
  openMenu: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default AppShell
