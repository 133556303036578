export const ATTRIBUTES = 'attributes'
export const CATEGORIES = 'categories'
export const PRODUCTS = 'products'
export const CATEGORY_ATTRIBUTES = 'categoryAttributes'
export const PRODUCT_ATTRIBUTES = 'productAttributes'
export const ORDERS = 'orders'
export const ORDER_ITEMS = 'orderItems'
export const ORDER_ITEM_ATTRIBUTES = 'orderItemAttributes'
export const ORDER_ITEM_ATTRIBUTE_ATTACHMENTS = 'orderItemAttributeAttachments'
export const CLIENT_USERS = 'clientUsers'
export const USERS = 'users'
export const PAGES = 'pages'
export const ORDER_ATTACHMENTS = 'orderAttachments'
