import megashopAttributeTypesEnums from './megashopAttributeTypesEnums'
import megashopContentTypes from './megashopContentTypes'
import megashopImageSizes from './megashopImageSizes'
import megashopOrderStates from './megashopOrderStates'
import megashopProductApps from './megashopProductApps'
import megashopUserRoles from './megashopUserRoles'

export const ATTRIBUTE_TYPES = 'megashopAttributeTypes'
export const CONTENT_TYPES = 'megashopContentTypes'
export const ORDER_STATES = 'megashopOrderStates'
export const IMAGE_SIZES = 'megashopImageSizes'
export const PRODUCT_APPS = 'megashopProductApps'
export const USER_ROLES = 'megashopUserRoles'

export default {
  [ATTRIBUTE_TYPES]: megashopAttributeTypesEnums,
  [CONTENT_TYPES]: megashopContentTypes,
  [ORDER_STATES]: megashopOrderStates,
  [IMAGE_SIZES]: megashopImageSizes,
  [PRODUCT_APPS]: megashopProductApps,
  [USER_ROLES]: megashopUserRoles,
}
