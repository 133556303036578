import S from 'fluent-json-schema'
import {EXPEDITION} from '../../../../constants/apps'
import * as expeditionSystemSettingNames from '../../../../constants/expedition/expeditionSystemSettings'
import * as baseSchemas from '../../baseSchemas'

export const EXPEDITION_PREPARATION_TIME = {
  name: expeditionSystemSettingNames.EXPEDITION_PREPARATION_TIME,
  schema: S.number().exclusiveMinimum(0).default(900000),
  public: true,
  app: EXPEDITION,
}

export const EXPEDITION_WORK_TIME = {
  name: expeditionSystemSettingNames.EXPEDITION_WORK_TIME,
  schema: baseSchemas.object({
    start: S.integer().minimum(0).default(9),
    end: S.integer().minimum(0).default(16),
  }),
  public: true,
  app: EXPEDITION,
}

export const ALL_EXPEDITION_SYSTEM_SETTINGS = [
  EXPEDITION_PREPARATION_TIME,
  EXPEDITION_WORK_TIME,
]
