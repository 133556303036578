import PropTypes from 'prop-types'
import ControlledDatagrid from '../../../components/containers/Datagrid/ControlledDatagrid'

const columns = [
  {accessor: 'id', Header: 'ID'},
  {accessor: 'name', Header: 'Názov'},
]

const PaymentTermsTable = ({
  paymentTerms,
  total,
  query,
  setQuery,
  selectedIds,
  setSelectedIds,
  setPaymentTermId,
  ...props
}) => {
  const handleRowClick = ({original: {id}}) => setPaymentTermId(id)

  return (
    <ControlledDatagrid
      data={paymentTerms}
      columns={columns}
      total={total}
      query={query}
      setQuery={setQuery}
      currentpage={query?.page}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      onRowClick={handleRowClick}
      {...props}
    />
  )
}

PaymentTermsTable.propTypes = {
  paymentTerms: PropTypes.array.isRequired,
  total: PropTypes.number,
  query: PropTypes.object,
  setQuery: PropTypes.func,
  selectedIds: PropTypes.array,
  setSelectedIds: PropTypes.func,
  setPaymentTermId: PropTypes.func.isRequired,
}

export default PaymentTermsTable
