import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import PropTypes from 'prop-types'
import * as resources from '../../../../../constants/resources'
import Button from '../../../components/containers/Button/Button'
import {Modal} from '../../../components/visual'
import {useOneResource, useUpdateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import ShippingMethodForm from './ShippingMethodsForm'

export const queryDef = ({shippingMethodId}) => ({
  app: '',
  resource: resources.SHIPPING_METHODS,
  id: shippingMethodId,
})

const ShippingMethodsEdit = ({
  refetch,
  setShippingMethodId,
  shippingMethodId,
}) => {
  const [shippingMethod] = useOneResource(queryDef({shippingMethodId}))
  const updateShippingMethod = useUpdateResource(
    null,
    resources.SHIPPING_METHODS,
    shippingMethodId,
  )
  const showAlert = useAlert()

  const handleClose = () => {
    setShippingMethodId(null)
  }

  const handleSubmit = async (values) => {
    await updateShippingMethod.mutateAsync(values)
    showAlert(`Spôsob odberu ${shippingMethodId} uložená`, 'success')
    if (refetch) refetch()
    handleClose()
    // Still need to handle errors somehow
  }

  if (!shippingMethod) return null

  return (
    <ShippingMethodForm onSubmit={handleSubmit} initialValues={shippingMethod}>
      {({submitting, form, handleSubmit}) => (
        <Modal open onClose={handleClose}>
          <Modal.Title>Spôsob odberu {shippingMethodId}</Modal.Title>
          <Modal.Content>{form}</Modal.Content>
          <Modal.Actions>
            <Button icon={<CloseIcon />} onClick={handleClose}>
              Zavrieť
            </Button>
            <Button
              icon={<SaveIcon />}
              variant="contained"
              disabled={submitting}
              onClick={handleSubmit}
            >
              Uložiť
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </ShippingMethodForm>
  )
}

ShippingMethodsEdit.propTypes = {
  refetch: PropTypes.func,
  setShippingMethodId: PropTypes.func.isRequired,
  shippingMethodId: PropTypes.number.isRequired,
}

export default ShippingMethodsEdit
