// eslint-disable-next-line import/no-unresolved
import 'react-international-phone/style.css'

import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(1)} 0`,
    width: ({wide}) => wide && '400px',

    [theme.breakpoints.down('sm')]: {
      width: ({wide}) => wide && '225px',
    },
  },
}))

const PhoneInput = ({value, onChange, helperText, label, ...restProps}) => {
  const {inputValue, handlePhoneValueChange, inputRef, country, setCountry} =
    usePhoneInput({
      defaultCountry: 'sk',
      defaultMask: '... ... ... ..',
      value,
      countries: defaultCountries,
      onChange: (data) => {
        if (!data.phone) return onChange('')
        if (data.phone.trim() === data.inputValue.trim()) return
        onChange(data.phone)
      },
    })
  const classes = useStyles()

  return (
    <TextField
      variant="outlined"
      label={label}
      color="primary"
      helperText={helperText}
      value={inputValue}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      className={classes.root}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment
              position="start"
              style={{marginRight: '2px', marginLeft: '-8px'}}
            >
              <Select
                MenuProps={{
                  style: {
                    height: '300px',
                    width: '360px',
                    top: '10px',
                    left: '-34px',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                sx={{
                  width: 'max-content',
                  fieldset: {
                    display: 'none',
                  },
                  '&.Mui-focused:has(div[aria-expanded="false"])': {
                    fieldset: {
                      display: 'block',
                    },
                  },
                  '.MuiSelect-select': {
                    padding: '8px',
                    paddingRight: '24px !important',
                  },
                  svg: {
                    right: 0,
                  },
                }}
                value={country.iso2}
                onChange={(e) => setCountry(e.target.value)}
                renderValue={(value) => (
                  <FlagImage iso2={value} style={{display: 'flex'}} />
                )}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c)
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagImage
                        iso2={country.iso2}
                        style={{marginRight: '8px'}}
                      />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="gray">+{country.dialCode}</Typography>
                    </MenuItem>
                  )
                })}
              </Select>
            </InputAdornment>
          ),
        },
      }}
      {...restProps}
    />
  )
}

PhoneInput.propTypes = {
  value: PropTypes.node,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  label: PropTypes.string,
}

export default PhoneInput
