import CloseIcon from '@mui/icons-material/Close'
import CreateIcon from '@mui/icons-material/Create'
import SaveIcon from '@mui/icons-material/Save'
import {If} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
import {useState} from 'react'
import * as resources from '../../../../../constants/resources'
import Button from '../../../components/containers/Button/Button'
import {Modal} from '../../../components/visual'
import {useCreateResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import PaymentTermsForm from './PaymentTermsForm'

const PaymentTermsCreate = ({refetch}) => {
  const createPaymentTerms = useCreateResource(null, resources.PAYMENT_TERMS)
  const [open, setOpen] = useState(false)
  const showAlert = useAlert()

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async (values) => {
    const {id} = await createPaymentTerms.mutateAsync(values)
    showAlert(`Forma úhrady ${id} vytvorená`, 'success')
    // Still need to handle errors somehow
    if (refetch) refetch()
    handleClose()
  }

  return (
    <>
      <Button
        icon={<CreateIcon />}
        onClick={handleOpen}
        variant="contained"
        data-test-id="createButton"
      >
        Vytvoriť
      </Button>
      <If condition={open}>
        <PaymentTermsForm onSubmit={handleSubmit}>
          {({submitting, form, handleSubmit}) => (
            <Modal open onClose={handleClose}>
              <Modal.Title>Vytvoriť Formu úhrady</Modal.Title>
              <Modal.Content>{form}</Modal.Content>
              <Modal.Actions>
                <Button icon={<CloseIcon />} onClick={handleClose}>
                  Zavrieť
                </Button>
                <Button
                  icon={<SaveIcon />}
                  variant="contained"
                  disabled={submitting}
                  onClick={handleSubmit}
                >
                  Uložiť
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </PaymentTermsForm>
      </If>
    </>
  )
}

PaymentTermsCreate.propTypes = {
  refetch: PropTypes.func,
}

export default PaymentTermsCreate
