import expeditionDispatchStates from './expeditionDispatchStates'
import expeditionDispatchingStates from './expeditionDispatchingStates'

export const EXPEDITION_DISPATCH_STATUSES = 'expeditionDispatchStates'
export const EXPEDITION_DISPATCHING_STATUSES = 'expeditionDispatchingStates'

export default {
  [EXPEDITION_DISPATCH_STATUSES]: expeditionDispatchStates,
  [EXPEDITION_DISPATCHING_STATUSES]: expeditionDispatchingStates,
}
