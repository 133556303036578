import {If} from 'babel-plugin-jsx-control-statements'
import {useState} from 'react'
import * as resources from '../../../../../constants/resources'
import DeleteButton from '../../../components/containers/DeleteButton/DeleteButton'
import Filters from '../../../components/containers/Filters/Filters'
import SearchBoxInput from '../../../components/form/SearchBoxInput/SearchBoxInput'
import {ScreenHeader} from '../../../components/visual'
import {useDeleteManyResources, useListResource} from '../../../hooks/api'
import {useAlert} from '../../../hooks/useAlert'
import useLocalSetting from '../../../hooks/useLocalSetting'
import ShippingMethodsCreate from './ShippingMethodsCreate'
import ShippingMethodsEdit from './ShippingMethodsEdit'
import ShippingMethodsTable from './ShippingMethodsTable'

export const queryDef = ({query} = {}) => ({
  app: '',
  resource: resources.SHIPPING_METHODS,
  query,
})

const initialQuery = {
  page: 0,
  pageSize: 10,
  filter: {deleted: false},
}

const queryName = 'shippingMethodsQuery'

const ShippingMethodsList = () => {
  const [selectedIds, setSelectedIds] = useState([])
  const [query, setQuery] = useLocalSetting(queryName, initialQuery)
  const {
    data: shippingMethods,
    total,
    refetch,
    isFetching,
  } = useListResource(queryDef({query}))
  const deleteShippingMethod = useDeleteManyResources(
    null,
    resources.SHIPPING_METHODS,
  )
  const [shippingMethodId, setShippingMethodId] = useState(null)
  const showAlert = useAlert()

  const handleDelete = async () => {
    await deleteShippingMethod.mutateAsync(selectedIds)

    setSelectedIds([])
    await refetch()
    showAlert('Vymazané', 'success')
  }

  return (
    <>
      <ScreenHeader title="Spôsob odberu">
        <DeleteButton
          onDelete={handleDelete}
          selectedIds={selectedIds}
          disabled={isFetching}
          data-test-id="deleteButton"
        />
        <ShippingMethodsCreate refetch={refetch} />
      </ScreenHeader>
      <Filters query={query} setQuery={setQuery}>
        <SearchBoxInput
          fullWidth={false}
          name="q"
          label="Vyhľadať spôsob odberu"
        />
      </Filters>
      <ShippingMethodsTable
        shippingMethods={shippingMethods}
        total={total}
        query={query}
        setQuery={setQuery}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        setShippingMethodId={setShippingMethodId}
      />
      <If condition={shippingMethodId}>
        <ShippingMethodsEdit
          setShippingMethodId={setShippingMethodId}
          shippingMethodId={shippingMethodId}
          refetch={refetch}
        />
      </If>
    </>
  )
}

export default ShippingMethodsList
